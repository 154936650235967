import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import { graphql } from 'gatsby'
import Wolflayout from '../components/wolflayout'
import PartnerSection from '../components/Partner/PartnerSection'
import Banner from '../components/NewTheme/Ui/Banner'
import { StaticImage } from 'gatsby-plugin-image'

const Partenaires = ({ data, location }) => {
  if (!data) return null

  function GetSection (section) {
    return section.map(({ node }) => {
      return {
        sponsor_url: node.data.sponsor_url.url,
        main_image: node.data.main_image,
        name: node.data.title.text,
        sort_order: node.data.sort_order
      }
    })
  }

  const colabSponsors = GetSection(
    data.allPrismicSponsors.edges.filter(({ node }) => {
      return node.data.section === 'Collaborateurs et présentateurs'
    })
  )

  const platiniumSponsors = GetSection(
    data.allPrismicSponsors.edges.filter(({ node }) => {
      return node.data.section === 'Platine'
    })
  )

  const goldSponsors = GetSection(
    data.allPrismicSponsors.edges.filter(({ node }) => {
      return node.data.section === 'Or'
    })
  )

  const silverSponsors = GetSection(
    data.allPrismicSponsors.edges.filter(({ node }) => {
      return node.data.section === 'Argent'
    })
  )

  const bronzeSponsors = GetSection(
    data.allPrismicSponsors.edges.filter(({ node }) => {
      return node.data.section === 'Bronze'
    })
  )

  const metaTitle = 'Partenaires | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Liste des précieux collaborateurs et partenaires de l’édition 2022 du Festif! de Baie-St-Paul'
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, partenaires, collaborateurs'
  return (
    <Wolflayout mobileTitle={'Partenaires'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} href={location.href} />
      <Banner title="Partenaires" />
      <div className="flex justify-center mt-8 pb-4 px-4 font-light text-lg text-center text-white">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl lg:max-w-4xl lg:px-4 text-center mx-auto">
            <p className="info-header-description mb-8">
              Le Festif! s'est bâti grâce à des partenaires incroyables qui ont
              cru en ce grand projet dès les premiers instants. Croire à une
              gang de jeunes rêveurs, ce n'est pas donné à tous. On ne
              soulignera jamais suffisamment la générosité de tous ceux qui,
              année après année, offrent leur appui au festival et lui
              permettent de briller de mille feux. Du fond du coeur, merci!
            </p>
          </div>
          <div className="flex flex-wrap justify-center items-center max-w-6xl mb-12 pt-8 px-4 lg:px-0 text-center !list-none">
            {colabSponsors.length > 0 && (
              <PartnerSection
                title=""
                partners={colabSponsors}
                useImage={true}
                allowBigSize={true}
              />
            )}
            {platiniumSponsors.length > 0 && (
              <PartnerSection
                title="Platine"
                partners={platiniumSponsors}
                useImage={true}
              />
            )}
            {goldSponsors.length > 0 && (
              <PartnerSection
                title="Or"
                partners={goldSponsors}
                useImage={true}
              />
            )}
            {silverSponsors.length > 0 && (
              <PartnerSection
                title="Argent"
                partners={silverSponsors}
                useImage={true}
              />
            )}
            {bronzeSponsors.length > 0 && (
              <PartnerSection
                title="Bronze"
                partners={bronzeSponsors}
                useImage={false}
                allowBigSize={true}
              />
            )}
          </div>
        </div>

      </div>

        {/* QUEBEC BANNER */}
        <div className="w-full center py-12">
          <div className="content text-center mb-8">
            <p>Le Festif! remercie la <a href="https://sodec.gouv.qc.ca/">SODEC</a> de son soutien financier.</p>
            <br/>
            <p>Nous reconnaissons l’appui financier du gouvernement du Canada et des radiodiffuseurs privés du Canada.</p>
          </div>
          <div className="max-w-[728px] max-h-[90px] mx-auto">
            <a className="" href="https://www.quebec.ca">
              <StaticImage src="../images/partenaires/quebec-banner.png"/>
            </a>
          </div>
        </div>

        {/* DEMANDE DU GOUVERNEMENT */}
        <div className='content text-align mb-8'>
          <div className='text-center mb-8'>
            <StaticImage className="max-w-[200px] max-h-[200px] mx-4" src="../images/partenaires/gouvernement/ministre1.jpg"/>
            <StaticImage className="max-w-[200px] max-h-[280px] mx-4" src="../images/partenaires/gouvernement/ministre2.jpg"/>
            <StaticImage className="max-w-[200px] max-h-[280px] mx-4" src="../images/partenaires/gouvernement/ministre3.jpg"/>
          </div>
          <p className='mb-4 text-center'><b>Un festival inoubliable dans la région</b></p>
          <p>Le gouvernement du Québec est heureux de soutenir Le Festif! de Baie-Saint-Paul, qui fait rayonner les artistes d’ici lors d’un événement de grande envergure dans la région de la Capitale-Nationale. Chaque année, les festivalières et festivaliers peuvent découvrir de nouveaux artistes d’ici et d’ailleurs tout en arpentant les rues de Baie-Saint-Paul. Félicitations à l’équipe organisatrice, qui a orchestré de main de maître ce rassemblement festif.</p>
          <p>Ce festival fait partie des rendez-vous incontournables de la culture musicale québécoise. Il possède un fort pouvoir d’attraction, contribue au rayonnement du savoir-faire des acteurs de nos industries événementielle et culturelle et stimule l’économie de la région.</p>
          <p>Voilà une belle façon de découvrir la culture d’ici tout en profitant de ce moment pour découvrir la région et ses plus beaux attraits!</p>
          <br/>
          <p className='mb-4'>Bon événement!</p>

          <p>La ministre du Tourisme<br/>et ministre responsable de la région de Lanaudière,</p>
          <p className='mb-4'><b>Caroline Proulx</b></p>

          <p>Le ministre de la Culture et des Communications,<br/>ministre responsable de la Jeunesse<br/>et ministre responsable de la région de l’Outaouais,</p>
          <p className='mb-4'><b>Mathieu Lacombe</b></p>

          <p>Le ministre responsable des Infrastructures<br/>et ministre responsable de la région de la Capitale-Nationale,</p>
          <p className='mb-4'><b>Jonatan Julien</b></p>

          <div className='ml-12 mt-8 text-left'>
            <StaticImage className="max-w-[200px] max-h-[200px] mx-4" src="../images/partenaires/gouvernement/votregouvernement.png"/>
            <StaticImage className="text-right max-w-[200px] max-h-[200px] mx-4" src="../images/partenaires/gouvernement/quebec_noir.png"/>
          </div>
        </div>

    </Wolflayout>
  )
}

export const query = graphql`
  query {
    allPrismicSponsors(sort: { fields: data___sort_order }) {
      edges {
        node {
          data {
            section
            main_image {
              alt
              gatsbyImageData(width: 250)
            }
            sponsor_url {
              url
            }
            title {
              text
            }
            sort_order
          }
        }
      }
    }
  }
`

export default Partenaires
