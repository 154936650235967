import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

/**
 *
 * @param {*} partners
 *  [
 *    {
 *      sponsor_url
 *      main_image
 *      name
 *      sort_order
 *    }
 *  ]
 *
 * @returns
 */
const PartnerSection = ({ title, partners, useImage, allowBigSize = false }) => {
  return (
    <>
      <p className="flex-wrap text-xl text-center w-full mt-9 mb-6 uppercase title-sponsor-section">
        {title}
      </p>
      <div className="sponsor flex flex-wrap justify-center items-center mr-2.5">
        {partners &&
          partners.map(({ sponsor_url: sponsorUrl, main_image: mainImage, name }, i) => {
            const image = getImage(mainImage)

            return (
              <a
                key={'partner' + i}
                className={`mr-0 mb-5 w-sponsorMobile lg:w-sponsor !no-underline text-white ${allowBigSize === false ? 'max-w-[100px] max-h-[100px]' : ''}`}
                href={sponsorUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {useImage && image
                  ? (
                  <GatsbyImage
                    className="w-auto"
                    image={image}
                    alt={mainImage.alt ? mainImage.alt : ''}
                  />
                    )
                  : !useImage
                      ? (
                      <span className="text-sm font-poppins uppercase mb-0 hover:underline text-white no-underline">
                      {name}
                    </span>
                        )
                      : (
                      <p className="text-sm font-poppins uppercase mb-0 hover:underline text-white no-underline !leading-4">
                      {name}
                    </p>
                        )
                  }
              </a>
            )
          })}
      </div>
    </>
  )
}

export default PartnerSection
